import { ClientEnvironment, ClientEnvironmentName } from '~/core/types/environment';

export const environment: ClientEnvironment = {
	environmentName: ClientEnvironmentName.STAGING,

	server: {
		url: 'https://api.staging.rea.ch',
	},

	mainClient: {
		url: 'https://mobile.staging.rea.ch',
	},

	handbook: {
		url: 'https://handbook.staging.rea.ch',
	},

	myGenieServer: {
		url: 'https://chat.staging.rea.ch',
	},
};
